const appConfig = {
  // apiPrefix: 'http://localhost:5003/api/',
  apiPrefix: "https://visitor.brothers.net.in/api/",
  // apiBaseURL: 'https://ra-masale-new-visitor-server.onrender.com',
  authenticatedEntryPath: "/dashboard",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  locale: "en",
  enableMock: true,
};

export default appConfig;
