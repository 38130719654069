import { useSelector, useDispatch } from "react-redux";
import appConfig from "../../configs/app.config";
import { REDIRECT_URL_KEY } from "../../constants/app.constant";
import { apiSignInRequest } from "../../services/AuthService";
import {
  onSignInSuccess,
  onSignOutSuccess,
} from "../../store/auth/sessionSlice";
import { initialState, setUser } from "../../store/auth/userSlice";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, signedIn } = useSelector((state) => state.auth.session);

  const signIn = async (values) => {
    try {
      const resp = await apiSignInRequest(values);
      if (resp.data) {
        const { token } = resp.data;
        dispatch(onSignInSuccess(token));
        if (resp.data?.data) {
          dispatch(
            setUser(
              {
                mobile:
                  resp.data.data.admin_mobile ||
                  resp.data.data.executive_mobile ||
                  resp.data.data.retailer_mobile,
                name:
                  resp.data.data.admin_name ||
                  resp.data.data.executive_name ||
                  resp.data.data.retailer_name,
                id:
                  resp.data.data.admin_id ||
                  resp.data.data.executive_id ||
                  resp.data.data.retailer_id,
                image:
                  resp.data.data.admin_image ||
                  resp.data.data.executive_image ||
                  resp.data.data.retailer_image,
                authority: resp.data.authority,
              } || {
                avatar: "",
                userName: "Anonymous",
                authority: ["USER"],
                email: "",
              }
            )
          );
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    handleSignOut();
  };

  return {
    authenticated: token && signedIn,
    // authenticated: true,
    signIn,
    signOut,
  };
}

export default useAuth;
